<template>
    <section id="dashboard-responses">
        <b-row v-if="$role(['answers.create'])" class="title mb-5">
            <b-col>
                <card-header v-bind="cardHeaderOptions"/>
            </b-col>
        </b-row>
        
        <b-tabs align="left" nav-class="font-weight-bold">
            <b-tab lazy title="Respostas">
                <responses-table />
            </b-tab>
            <b-tab lazy title="Assuntos">
                <subjects-table />
            </b-tab>
        </b-tabs>
    </section>
</template>

<script>
import {BRow, BCol, BTab, BTabs} from "bootstrap-vue";
import CardHeader from "@/views/components/CardHeader.vue";
import ResponsesTable from "./ResponsesTable.vue";
import SubjectsTable from "./SubjectsTable.vue";

export default {
    components: {
        BRow,
        BCol,
        CardHeader,
        BTab,
        BTabs,
        ResponsesTable,
        SubjectsTable
    },
    data() {
        return {
            cardHeaderOptions: {
                title: "Criar novas respostas",
                description:
                    "Aqui você pode gerar uma ou várias respostas automáticas em seqüências para as questões recorrentes no seu negócio.",
                icon: require("@/assets/images/icons/new-topic.svg"),
                link: "responses/new",
                textButton: "Criar Resposta",
            },
        };
    },
};
</script>
