<template>
    <b-card class="text-center">
        <loading v-if="loading"/>
        <common-table-custom
            :show-details="false"
            title="Assuntos"
            v-bind="configTableSubject"
        >
            <template v-slot:footer>
                <td class="text-left">Total</td>
                <td>{{ configTableSubject.misc ? configTableSubject.misc.total : 0 }}</td>
                <td
                    :colspan="5"
                    class="text-right"
                >
                    <b-link
                        v-if="configTableSubject.misc && configTableSubject.misc.current_page != configTableSubject.misc.last_page"
                        @click="getSubjects(configTableSubject.misc.current_page + 1)"
                    >
                        + Mostrar mais
                    </b-link>
                </td>
            </template>
        </common-table-custom>
        <save-subject-modal v-model="subject"/>
    </b-card>
</template>


<script>
import {BCard, BLink} from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";
import api from "@/api";
import SaveSubjectModal from "./components/SaveSubjectModal.vue";
import moment from "moment";

export default {
    components: {
        BCard,
        CommonTableCustom,
        Loading,
        BLink,
        SaveSubjectModal
    },
    watch: {
        subject(val) {
            if(val != null) {
                const index = this.configTableSubject.items.findIndex(e => e._id == val._id);
                if(index >= 0) {
                    Object.assign(this.configTableSubject.items[index], val);
                    this.$forceUpdate();
                }
            }
        }
    },
    data() {
        return {
            configTableSubject: {
                fields: [
                    {
                        key: "name",
                        label: "Assunto",
                        sortable: false,
                        class: "text-left",
                    },
                    {
                        key: "answers_count",
                        label: "Respostas",
                        sortable: false,
                        class: "text-center",
                    },
                    {
                        key: "created_at",
                        label: "Data de criação",
                        sortable: false,
                        formatter: value => {
                            return moment(value).format('DD/MM/YYYY')
                        },
                    },
                    {
                        key: "actions",
                        label: "",
                        class: "text-right d-flex justify-content-around",
                        sortable: false,
                    },
                ],
                items: [],
                misc: {},
                actions: [
                    {
                        label: "Editar",
                        name: "edit",
                        onClick: this.editSubject,
                        roles: ['answers.update']
                    },
                    {
                        label: "Deletar",
                        name: "delete",
                        onClick: this.deleteSubject,
                        roles: ['answers.delete']
                    },
                ].filter(e => this.$role(e.roles))
            },
            loading: false,
            subject: null
        };
    },
    created() {
        this.getSubjects();
    },
    methods: {
        getSubjects(page = 1) {
            this.loading = true;
            api
                .get('/answers/subjects', {query: {page}})
                .then(response => {
                    const {type, message, body, misc} = response.data;

                    if (type == 'success') {
                        this.configTableSubject.misc = misc;
                        return this.configTableSubject.items = body;
                    }
                    throw message;
                })
                .catch(() => {
                    this.$toast('Não foi possível carregar a lista de assuntos.', {title: 'Assuntos', type: 'danger'})
                })
                .finally(() => this.loading = false);
        },
        deleteSubject(id) {
            this.$swal({
                title: 'Tem certeza que deseja remover?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Remover',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.delete(`/answers/subjects/${id}`)
                        .then(res => {
                            const {type, message} = res.data;
                            if (type != 'success') {
                                throw new Error(message)
                            }
                            const i = this.configTableSubject.items.findIndex(e => e._id == id);
                            this.configTableSubject.misc.total -= 1;
                            if (i >= 0) this.configTableSubject.items.splice(i, 1);
                        })
                        .catch(() => {
                            this.$swal('Erro', 'Não foi possível remover este assunto', 'error');
                        })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            })
        },
        editSubject(id) {
            this.subject = this.configTableSubject.items.find(e => e._id == id);
            setTimeout(() => this.$bvModal.show('saveSubjectModal'), 200);
        },
    },
};
</script>    