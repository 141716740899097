<template>
    <b-card class="text-center">
        <loading v-if="loading"/>
        <common-table-custom
            :show-details="false"
            title="Respostas e Sequências existentes"
            v-bind="configTableResponse"
        >
            <template v-slot:footer>
                <td class="text-left">Total</td>
                <td>{{ configTableResponse.misc ? configTableResponse.misc.total : 0 }}</td>
                <td
                    :colspan="5"
                    class="text-right"
                >
                    <b-link
                        v-if="configTableResponse.misc && configTableResponse.misc.current_page != configTableResponse.misc.last_page"
                        @click="getAnswers(configTableResponse.misc.current_page + 1)"
                    >
                        + Mostrar mais
                    </b-link>
                </td>
            </template>
        </common-table-custom>
        <answer-test-modal :value="answerTest.data"/>
    </b-card>
</template>


<script>
import {BCard, BLink} from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";
import AnswerTestModal from './components/AnswerTestModal.vue';
import api from "@/api";
import moment from "moment";

export default {
    components: {
        BCard,
        CommonTableCustom,
        AnswerTestModal,
        Loading,
        BLink,
    },
    data() {
        return {
            configTableResponse: {
                fields: [
                    {
                        key: "name",
                        label: "Nome",
                        sortable: false,
                        class: "text-left",
                    },
                    {
                        key: "sequential_count",
                        label: "Sequências",
                        sortable: false,
                        formatter: value => value == 0 ? null : value
                    },
                    {
                        key: "subject.name",
                        label: "Assunto",
                        sortable: false
                    },
                    {
                        key: "active",
                        label: "Status",
                        sortable: false,
                        formatter: value => value != false ? 'Ativo' : 'Desativado'
                    },
                    {
                        key: "created_at",
                        label: "Data de criação",
                        sortable: false,
                        formatter: value => {
                            return moment(value).format('DD/MM/YYYY')
                        },
                    },
                    {
                        key: "actions",
                        label: "",
                        class: "text-right d-flex justify-content-around",
                        sortable: false,
                    },
                ],
                items: [],
                misc: {},
                actions: [
                    {
                        label: "Duplicar",
                        name: "duplicate",
                        onClick: this.duplicateAnswer,
                        roles: ['answers.create']
                    },
                    {
                        label: "Editar",
                        name: "edit",
                        onClick: this.editAnswer,
                        roles: ['answers.update']
                    },
                    {
                        label: "Ativar/Desativar",
                        name: "toggle",
                        onClick: this.toggleAnswer,
                        roles: ['answers.update']
                    },
                    {
                        label: "Deletar",
                        name: "delete",
                        onClick: this.deleteAnswer,
                        roles: ['answers.delete']
                    },
                    {
                        label: "Testar",
                        name: "test",
                        onClick: this.testAnswer,
                        roles: ['answers.update', 'answers.create']
                    },
                ].filter(e => this.$role(e.roles))
            },
            answerTest: {
                data: null,
                show: false
            },
            loading: false
        };
    },
    created() {
        this.getAnswers();
    },
    methods: {
        getAnswers(page = 1) {
            this.loading = true;
            api
                .get('/answers', {query: {page}})
                .then(response => {
                    const {type, message, body, misc} = response.data;

                    if (type == 'success') {
                        this.configTableResponse.misc = misc;
                        return this.configTableResponse.items = body;
                    }
                    throw message;
                })
                .catch(() => {
                    this.$toast('Não foi possível carregar a lista de respostas.', {title: 'Respostas', type: 'error'})
                })
                .finally(() => this.loading = false);
        },
        deleteAnswer(id) {
            this.$swal({
                title: 'Tem certeza que deseja remover?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Remover',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.delete(`/answers/${id}`)
                        .then(res => {
                            const {type, message} = res.data;
                            if (type != 'success') {
                                throw new Error(message)
                            }
                            const i = this.configTableResponse.items.findIndex(e => e._id == id);
                            this.configTableResponse.misc.total -= 1;
                            if (i >= 0) this.configTableResponse.items.splice(i, 1);
                        })
                        .catch(() => {
                            this.$swal('Erro', 'Não foi possível remover esta resposta', 'error');
                        })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            })
        },
        toggleAnswer(id) {
            const answer = this.configTableResponse.items.find(e => e._id == id);

            if(!answer) return;

            this.$swal({
                title: 'Tem certeza que deseja ativar/desativar esta resposta?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.patch(`/answers/${id}`, { active: !(answer.active != false)})
                        .then(res => {
                            const {type, message, body} = res.data;
                            if (type != 'success') {
                                throw new Error(message)
                            }
                            const i = this.configTableResponse.items.findIndex(e => e._id == id);
                            Object.assign(this.configTableResponse.items[i], body);
                        })
                        .catch(e => {
                            console.log(e)
                            this.$swal('Erro', 'Não foi possível ativar/desativar esta resposta', 'error');
                        })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            })
        },
        duplicateAnswer(id) {
            this.$swal({
                title: 'Tem certeza que deseja duplicar esta resposta?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Duplicar',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.post(`/answers/${id}`)
                        .then(res => {
                            const {type, message, body} = res.data;
                            if (type != 'success') {
                                throw new Error(message)
                            }
                            const i = this.configTableResponse.items.findIndex(e => e._id == id);
                            this.configTableResponse.items.splice(i + 1, 0, body);
                            this.configTableResponse.misc.total += 1;
                        })
                        .catch(e => {
                            console.log(e)
                            this.$swal('Erro', 'Não foi possível duplicar esta resposta', 'error');
                        })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            })
        },
        editAnswer(id) {
            this.$router.push({
                name: 'responses/edit',
                params: {id}
            });
        },
        testAnswer(id) {
            this.loading = true
            api
                .get(`/answers/${id}`)
                .then(response => {
                    const {type, message, body} = response.data;

                    if (type == 'success') {
                        this.answerTest.data = body;
                        return this.$bvModal.show('answerTestModal')
                    }
                    this.answerTest = null;
                    throw message
                })
                .catch(() => this.$toast('Não foi possível carregar o teste da resposta.', {
                    title: 'Respostas',
                    type: 'danger'
                }))
                .finally(() => this.loading = false)
        }
    },
};
</script>    